<template>
  <div
    class="canhover:flex hidden items-center relative"
    :class="disabled && 'disabled'"
    v-bind="$attrs"
  >
    <div
      v-if="label"
      class="absolute pointer-events-none left-2 -top-3 font-light"
    >
      <div
        class="absolute z-[1] bottom-0 w-full h-[calc(50%)] bg-neutral-100 dark:bg-neutral-900"
      ></div>
      <label class="relative z-[2] rounded-md px-1 text-xs">
        {{ label }}
      </label>
    </div>
    <button
      class="p-2 flex items-center select-none cursor-pointer w-full rounded-md border transition-colors bg-neutral-100 dark:bg-neutral-900 text-neutral-800 dark:text-neutral-200 border-neutral-400 dark:border-neutral-600 canhover:hover:border-neutral-500 canhover:hover:dark:border-neutral-500 focus:border-neutral-800 focus:dark:border-neutral-300 canhover:focus:border-neutral-800 canhover:focus:dark:border-neutral-300"
      @click.prevent="showTimePicker = true"
      ref="showTimePickerButton"
    >
      {{ modelValue.format("hh:mm A") }}
    </button>
    <TransitionFade>
      <div
        v-show="showTimePicker"
        ref="timePicker"
        class="absolute z-50 w-full flex justify-center"
        :class="{
          'bottom-14': position === 'top',
          'top-16': position === 'bottom',
        }"
      >
        <div
          class="bg-neutral-100 dark:bg-neutral-900 border border-neutral-400 dark:border-neutral-600 rounded-md"
        >
          <div class="mt-6 flex justify-center items-center space-x-1 text-2xl">
            <div class="w-10 text-right">
              <span
                class="px-1 cursor-pointer rounded-md transition hover:bg-neutral-200 dark:hover:bg-neutral-700"
                :class="{
                  'opacity-50': currentlySelectingMode !== 'hour',
                }"
                @click="currentlySelectingMode = 'hour'"
              >
                {{
                  is24h
                    ? currentlySelectedHour.toString().padStart(2, "0")
                    : currentlySelectedHour
                }}
              </span>
            </div>
            <span class="text-neutral-700 dark:text-neutral-400 select-none">
              :
            </span>
            <div class="w-10 h-8 text-left flex">
              <span
                v-if="!isMinuteInputMode"
                class="px-1 cursor-pointer rounded-md transition hover:bg-neutral-200 hover:dark:bg-neutral-700"
                :class="{
                  'opacity-50': currentlySelectingMode !== 'minute',
                }"
                @click="
                  () => {
                    if (currentlySelectingMode !== 'minute') {
                      currentlySelectingMode = 'minute';
                    } else {
                      changeToInputMinuteMode();
                    }
                  }
                "
              >
                {{ currentlySelectedMinute.toString().padStart(2, "0") }}
              </span>
              <input
                v-else
                class="minute-input px-1 min-w-0 w-8 h-8 border-none m-0 rounded-md transition bg-neutral-200 dark:bg-neutral-700 text-center text-lg"
                :value="currentlySelectedMinute.toString().padStart(2, '0')"
                type="number"
                @change="
                  updateMinute(
                    parseInt(($event.target as HTMLInputElement).value),
                  )
                "
                @blur="isMinuteInputMode = false"
                @keydown.enter="isMinuteInputMode = false"
                @keydown.escape.prevent="isMinuteInputMode = false"
              />
            </div>
          </div>
          <div
            v-if="!is24h"
            class="flex justify-center"
            @click="currentAmPm = currentAmPm === 'AM' ? 'PM' : 'AM'"
          >
            <NamiButton buttonType="secondary" text small>
              {{ currentAmPm }}
            </NamiButton>
          </div>
          <div class="p-2 px-4 relative flex justify-center">
            <div class="p-1 relative rounded-full">
              <div
                class="relative flex flex-col justify-center w-[12rem] h-[12rem]"
              >
                <div
                  v-if="currentlySelectingMode === 'minute'"
                  class="absolute w-full h-full transition"
                  ref="minuteBubbles"
                >
                  <div
                    v-for="(minute, index) in allMinutes"
                    class="absolute select-none cursor-pointer flex flex-col justify-center w-8 h-8 rounded-full text-center text-sm"
                    :class="{
                      'bg-nami-comi-blue text-black z-[1]':
                        minute === currentlySelectedMinute,
                      'text-neutral-700 dark:text-neutral-400 hover:bg-neutral-200 dark:hover:bg-neutral-700':
                        minute !== currentlySelectedMinute,
                    }"
                    :style="{
                      left: `${
                        5 *
                          Math.sin((index * 2 * Math.PI) / allMinutes.length) +
                        5
                      }rem`,
                      bottom: `${
                        5 *
                          Math.cos((index * 2 * Math.PI) / allMinutes.length) +
                        5
                      }rem`,
                    }"
                    @mousedown="
                      (e) => {
                        onDrag(e);
                        registerAfterBeginDragListerners();
                      }
                    "
                    @click="updateMinute(minute)"
                  >
                    {{ minute }}
                  </div>
                </div>
                <div
                  v-if="currentlySelectingMode === 'hour'"
                  class="absolute w-full h-full transition"
                  ref="hourBubbles"
                >
                  <div
                    v-for="(hour, index) in allHours.slice(0, 12)"
                    class="absolute select-none cursor-pointer flex flex-col justify-center w-8 h-8 rounded-full text-center text-sm"
                    :class="{
                      'bg-nami-comi-blue text-black z-[1]':
                        hour === currentlySelectedHour,
                      'text-neutral-700 dark:text-neutral-400 hover:bg-neutral-200 dark:hover:bg-neutral-700':
                        hour !== currentlySelectedHour,
                    }"
                    :style="{
                      left: `${
                        5 * Math.sin((index * 2 * Math.PI) / 12) + 5
                      }rem`,
                      bottom: `${
                        5 * Math.cos((index * 2 * Math.PI) / 12) + 5
                      }rem`,
                    }"
                    @mousedown="
                      (e) => {
                        onDrag(e);
                        registerAfterBeginDragListerners();
                      }
                    "
                    @click="updateHour(hour)"
                  >
                    {{ hour }}
                  </div>
                  <div
                    v-if="is24h"
                    v-for="(hour, index) in allHours.slice(12)"
                    class="absolute select-none cursor-pointer flex flex-col justify-center w-8 h-8 rounded-full text-center text-sm"
                    :class="{
                      'bg-nami-comi-blue text-black z-[1]':
                        hour === currentlySelectedHour,
                      'text-neutral-700 dark:text-neutral-400 hover:bg-neutral-200 dark:hover:bg-neutral-700':
                        hour !== currentlySelectedHour,
                    }"
                    :style="{
                      left: `${
                        3 * Math.sin((index * 2 * Math.PI) / 12) + 5
                      }rem`,
                      bottom: `${
                        3 * Math.cos((index * 2 * Math.PI) / 12) + 5
                      }rem`,
                    }"
                    @mousedown="
                      (e) => {
                        onDrag(e);
                        registerAfterBeginDragListerners();
                      }
                    "
                    @click="updateHour(hour)"
                  >
                    {{ hour }}
                  </div>
                </div>
                <div
                  class="mx-auto w-2 h-2 rounded-full bg-nami-comi-blue"
                ></div>
                <div
                  v-if="currentlySelectingMode === 'minute'"
                  class="absolute w-[0.125rem] h-[70%] top-[15%] left-[calc(50%-1px)] pointer-events-none"
                  :style="{
                    transform: `rotate(${
                      (Math.floor(currentlySelectedMinute / minuteInterval) *
                        2 *
                        Math.PI) /
                      allMinutes.length
                    }rad)`,
                  }"
                >
                  <div class="h-[calc(50%-2px)] bg-nami-comi-blue"></div>
                  <div class="h-[calc(50%+2px)] bg-transparent"></div>
                </div>
                <div
                  v-if="currentlySelectingMode === 'hour'"
                  class="absolute w-[0.125rem] left-[calc(50%-1px)] pointer-events-none"
                  :class="{
                    'h-[70%] top-[15%]': !is24h || currentlySelectedHour < 12,
                    'h-[40%] top-[30%]': is24h && currentlySelectedHour >= 12,
                  }"
                  :style="{
                    transform: `rotate(${
                      (Math.floor(currentlySelectedHour % 12) * 2 * Math.PI) /
                      12
                    }rad)`,
                  }"
                >
                  <div class="h-[calc(50%-2px)] bg-nami-comi-blue"></div>
                  <div class="h-[calc(50%+2px)] bg-transparent"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="m-2 flex justify-between">
            <NamiButton buttonType="secondary" text @click="is24h = !is24h">
              {{ is24h ? "12h" : "24h" }}
            </NamiButton>
            <div class="flex space-x-2">
              <NamiButton
                buttonType="danger"
                text
                @click="emit('update:modelValue', initialDate)"
              >
                Reset
              </NamiButton>
              <NamiButton
                buttonType="primary"
                text
                @click="showTimePicker = false"
              >
                Done
              </NamiButton>
            </div>
          </div>
        </div>
      </div>
    </TransitionFade>
  </div>
  <div v-bind="$attrs" class="canhover:hidden relative flex items-center">
    <div
      v-if="label"
      class="absolute pointer-events-none left-2 -top-3 font-light"
    >
      <div
        class="absolute z-[1] bottom-0 w-full h-[calc(50%)] bg-neutral-100 dark:bg-neutral-900"
      ></div>
      <label class="relative z-[2] rounded-md px-1 text-xs">
        {{ label }}
      </label>
    </div>
    <input
      type="time"
      class="block w-full rounded-md border bg-neutral-100 dark:bg-neutral-900 text-neutral-800 dark:text-neutral-200 border-neutral-400 dark:border-neutral-600 canhover:hover:border-neutral-500 canhover:hover:dark:border-neutral-500 focus:border-neutral-800 focus:dark:border-neutral-300 canhover:focus:border-neutral-800 canhover:focus:dark:border-neutral-300"
      :value="modelValue.format('HH:mm:ss')"
      @input="updateTime"
    />
  </div>
</template>

<script setup lang="ts">
import type { Dayjs } from "dayjs";
import type { AvailableLanguages } from "~/src/api";

interface Props {
  modelValue: Dayjs;
  label?: string;
  locale: AvailableLanguages;
  disabled?: boolean;
  forcePosition?: "top" | "bottom";
}

interface Events {
  (e: "update:modelValue", v: Props["modelValue"]): void;
}

const props = defineProps<Props>();
const emit = defineEmits<Events>();

const initialDate = props.modelValue;
const position = ref<"top" | "bottom">(props.forcePosition ?? "top");

const currentlySelectingMode = ref<"minute" | "hour">("hour");
const isMinuteInputMode = ref(false);
const is24h = ref(true);
const currentAmPm = ref<"PM" | "AM">("PM");
watch(
  () => currentAmPm.value,
  () => updateHour(props.modelValue.hour()),
);
watch(
  () => is24h.value,
  (newVal) => {
    const currentHour = props.modelValue.hour();
    if (!newVal) currentAmPm.value = currentHour >= 12 ? "PM" : "AM";
  },
);
const currentlySelectedHour = computed(() =>
  is24h.value
    ? props.modelValue.hour()
    : props.modelValue.hour() % 12 || (is24h.value ? 0 : 12),
);
const currentlySelectedMinute = computed(() => props.modelValue.minute());

const minuteInterval = 5;
const allMinutes = Array(Math.floor(60 / minuteInterval))
  .fill(null)
  .map((_, index) => index * minuteInterval);
const allHours = computed(() =>
  Array(is24h.value ? 24 : 12)
    .fill(null)
    .map((_, index) => (index === 0 ? (is24h.value ? 0 : 12) : index)),
);

const timePicker = ref<HTMLDivElement>();
const showTimePickerButton = ref<HTMLDivElement>();
const showTimePicker = ref(false);

const minuteBubbles = ref<HTMLDivElement>();
const hourBubbles = ref<HTMLDivElement>();

function updateTime(e: Event) {
  const [hour, minute, second] = (e.target as HTMLInputElement).value
    .split(":")
    .map((val) => parseInt(val));
  emit(
    "update:modelValue",
    props.modelValue.hour(hour).minute(minute).second(second),
  );
}

watch(
  () => showTimePicker.value,
  (newVal) => {
    const rect = showTimePickerButton.value?.getBoundingClientRect();
    if (!rect) return;

    if (!props.forcePosition) {
      if (rect.y - 360 - window.scrollY < 0) position.value = "bottom";
      else position.value = "top";
    }

    setTimeout(() => {
      newVal ? registerClickListener() : unregisterClickListener();
    }, 300);
  },
);

function handleClick(e: MouseEvent | TouchEvent) {
  if (!timePicker.value?.children[0]) return;

  let tapX = 0;
  let tapY = 0;

  if (e instanceof MouseEvent) {
    tapX = e.clientX;
    tapY = e.clientY;
  } else {
    tapX = e.touches[0].clientX;
    tapY = e.touches[0].clientY;
  }

  const rect = timePicker.value.children[0].getBoundingClientRect();

  if (
    tapX > rect.x + rect.width ||
    tapX < rect.x ||
    tapY > rect.y + rect.height ||
    tapY < rect.y
  ) {
    showTimePicker.value = false;
  }
}

function onDrag(e: MouseEvent) {
  if (
    (currentlySelectingMode.value === "minute" && !minuteBubbles.value) ||
    (currentlySelectingMode.value === "hour" && !hourBubbles.value)
  )
    return;

  const { clientX, clientY } = e;

  // Select all our items whether we are on minutes or hours
  const bubbles =
    currentlySelectingMode.value === "minute"
      ? minuteBubbles.value!
      : hourBubbles.value!;
  // Map them with their boundingClientRects
  const allBubbleRects = Array.from(bubbles.children).map((minute) =>
    minute.getBoundingClientRect(),
  );
  // Calculate the 2d distance from the pointer to the items
  const distances = allBubbleRects.map((rect) =>
    Math.sqrt(
      Math.pow(clientX - (rect.x + rect.width / 2), 2) +
        Math.pow(clientY - (rect.y + rect.height / 2), 2),
    ),
  );
  // Get the smallest one
  const smallestDistance = distances.reduce((a, b) => (a <= b ? a : b));
  // Find its index
  const smallestDistanceIndex = distances.findIndex(
    (distance) => distance === smallestDistance,
  );

  if (typeof smallestDistanceIndex !== "number") return;

  if (currentlySelectingMode.value === "minute")
    updateMinute(smallestDistanceIndex * minuteInterval);
  else
    updateHour(
      smallestDistanceIndex % (is24h.value ? 24 : 12) || (is24h.value ? 0 : 12),
    );
}

function updateMinute(minute: number) {
  const withinRange = Math.min(Math.max(minute, 0), 59);
  emit("update:modelValue", props.modelValue.minute(withinRange));
}

function updateHour(hour: number) {
  emit(
    "update:modelValue",
    props.modelValue.hour(
      (is24h.value ? hour % 24 : hour % 12) +
        (is24h.value || currentAmPm.value === "AM" ? 0 : 12),
    ),
  );
}

function changeToInputMinuteMode() {
  isMinuteInputMode.value = true;
  setTimeout(() => {
    (document.querySelector(".minute-input") as HTMLInputElement)?.focus();
  }, 30);
}

function registerClickListener() {
  document.addEventListener("click", handleClick);
}

function unregisterClickListener() {
  document.removeEventListener("click", handleClick);
}

function registerAfterBeginDragListerners() {
  document.addEventListener("mousemove", onDrag);
  document.addEventListener("mouseup", unregisterAfterBeginDragListerners);
  unregisterClickListener();
}

function unregisterAfterBeginDragListerners() {
  document.removeEventListener("mousemove", onDrag);
  document.removeEventListener("mouseup", unregisterAfterBeginDragListerners);
  currentlySelectingMode.value = "minute";
  registerClickListener();
}
</script>
